.navbar-section {
    margin: 10px;
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color:#ffffff38;
    width: auto;
    border: 2px;
    border-radius: 5px;
}
.navbar-title a {
    color: #f7941d;
    letter-spacing: .6px;
    text-decoration: none;
}
.navbar-title {
    font-family: 'Poppins', sans-serif;
}
.navbar-sign {
    color: #54de54;
    font-family: Cambria, sans-serif;
    font-size: 40px;
    font-weight: bold;
}
.navbar-items {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    font-family: 'Rubik', sans-serif;
}
.navbar-items > li {
    list-style-type: none;
}
.navbar-links {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 18px;
    letter-spacing: .8px;
}
.navbar-links:hover {
    color: #2c6598;
}
.navbar-btn {
    margin: 10px;
    padding: 14px 20px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    outline: transparent;
    background-color: #2c6598;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}
.navbar-btn:hover {
    color: #2c6598;
    background-color: white;
    border: 1px solid #2c6598;
}
/* Hamburger Icon */
.mobile-nav {
    display: none;
}
.hamb-icon {
    width: 26px;
    height: 26px;
    cursor: pointer;
}
.hamb-icon:hover {
    color: #0cc2ea;
}
/* Mobile Navbar */
.mobile-navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    background-color: rgba(255, 255, 255, 0.575);
    z-index: 20;
    justify-content: center;
    align-items: center;
    transition: left .5s ease-in-out;
}
.mobile-navbar-close {
    position: absolute;
    top: 28px;
    right: 28px;
}
.mobile-navbar-close .hamb-icon:hover {
    color: rgb(255, 22, 22);
}
.open-nav {
    left: 0;
}
.mobile-navbar-links {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    gap: 24px;
    text-align: center;
}
.mobile-navbar-links li a {
    text-decoration: none;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: .8px;
    transition: color .3s ease-in-out;
}
.mobile-navbar-links li a:hover {
    color: #0cc2ea;
}
/* Responsive */
@media screen and (max-width: 900px) {
    .navbar-btn, .navbar-items {
        display: none;
    }
    .mobile-nav {
        display: block;
    }
}
