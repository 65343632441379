/* FloodContent.css - Integrated and Optimized */

/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flood-content {
  font-family: 'Arial', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  padding-bottom: 100px;
  min-height: 100vh;
  color: #333;
  line-height: 1.6;
  overflow-x: hidden;
}

/* Typography */
h1, h2, h3, h4 {
  font-weight: 700;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
  color: #333;
}

h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

h3 {
  font-size: 1.4rem;
  color: #00a0e3;
  margin-top: 0;
  margin-bottom: 10px;
}

p {
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 8px;
}

button {
  cursor: pointer;
  font-family: inherit;
}

.highlight-text {
  color: #ff7f00;
  font-weight: 700;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

/* Header Styles */
.flood-header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
}

.logo {
  margin-bottom: 10px;
}

.logo img {
  max-width: 150px;
  display: block;
  margin: 0 auto;
}

.emergency-banner {
  display: flex;
  align-items: center;
  background-color: #ff7f00;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  position: relative;
  margin: 10px 0;
  text-align: center;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.emergency-banner h2 {
  margin: 0;
  font-size: 20px;
  color: white;
}

.emergency-pulse {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  margin-right: 10px;
  animation: pulsate 1.5s infinite;
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    opacity: 1;
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    opacity: 0.8;
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    opacity: 1;
  }
}

.contact-info {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.contact-info h2 {
  margin: 5px 0;
  font-size: 24px;
  color: #00a0e3;
}

.contact-info h3 {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.phone-number {
  font-size: 24px;
  font-weight: 700;
  color: white;
  background-color: #ff7f00;
  text-decoration: none;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 5px;
  transition: background-color 0.3s, transform 0.3s;
}

.phone-number:hover {
  background-color: #e67300;
  transform: translateY(-2px);
}

/* Hero Section */
.hero-section {
  position: relative;
  height: 50vh;
  min-height: 300px;
  background-image: url('../Assets/dryer-hardwood-image.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  border-radius: 8px;
  overflow: hidden;
}

.hero-section:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

.hero-overlay {
  position: relative;
  text-align: center;
  color: white;
  padding: 2rem;
  max-width: 800px;
  z-index: 1;
}

.hero-overlay h1 {
  font-size: 2.5rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
  margin-bottom: 1rem;
  color: white;
}

.hero-overlay h2 {
  font-size: 1.3rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
  margin-bottom: 2rem;
  color: white;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.cta-button {
  padding: 0.8rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 700;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: transform 0.3s, box-shadow 0.3s;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.call-now {
  background-color: #ff7f00;
  color: white;
}

.schedule {
  background-color: #00a0e3;
  color: white;
}

/* Main Content */
.main-content {
  margin-bottom: 30px;
}

.content-flex {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text-content, .image-content {
  width: 100%;
}

.text-content p {
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.call-now {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff7f00;
  margin: 20px 0;
}

.image-content img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-caption {
  margin-top: 8px;
  font-style: italic;
  text-align: center;
  color: #666;
}

/* Service Tabs */
.service-tabs {
  margin: 30px 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tabs-header {
  display: flex;
}

.tabs-header button {
  flex: 1;
  padding: 15px;
  border: none;
  background-color: #f0f0f0;
  font-weight: 700;
  font-size: 1.1rem;
  transition: background-color 0.3s;
}

.tabs-header button.active {
  background-color: #00a0e3;
  color: white;
}

.tab-content {
  padding: 20px;
  background-color: white;
  border: 1px solid #f0f0f0;
}

.benefits-list {
  margin: 20px 0;
}

.benefits-list li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 12px;
  font-size: 1.1rem;
}

.emergency-tips {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  margin: 20px 0;
  border-left: 4px solid #ff7f00;
}

.emergency-tips h3 {
  color: #ff7f00;
  margin-bottom: 10px;
}

.emergency-tips ul li {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.emergency-button {
  display: inline-block;
  background-color: #ff7f00;
  color: white;
  padding: 12px 24px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 15px;
  transition: background-color 0.3s, transform 0.3s;
}

.emergency-button:hover {
  background-color: #e67300;
  transform: translateY(-2px);
}

/* Why Choose Section */
.why-choose-section {
  margin: 40px 0;
  text-align: center;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.benefit-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.benefit-card:hover {
  transform: translateY(-5px);
}

.benefit-icon {
  font-size: 2.5rem;
  color: #00a0e3;
  margin-bottom: 15px;
}

.benefit-card h3 {
  margin-bottom: 10px;
}

/* Testimonials Section */
.testimonials-section {
  margin: 40px 0;
  text-align: center;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.testimonial-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.testimonial-card .stars {
  color: #ff7f00;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.testimonial-text {
  font-style: italic;
  margin-bottom: 15px;
}

.testimonial-author {
  font-weight: 700;
  text-align: right;
  margin-top: auto;
}

/* Resources Section */
.resources-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 40px 0;
  background-color: #f5f5f5;
  padding: 25px;
  border-radius: 8px;
}

.mascot {
  width: 100px;
}

.mascot img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  border: 3px solid #00a0e3;
}

.resto-resources-card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.resto-resources-card h3 {
  color: #00a0e3;
  margin-bottom: 15px;
  font-size: 1.4rem;
}

.resto-resources-card ul {
  margin-bottom: 20px;
}

.resto-resources-card li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
  font-size: 1rem;
}

.resto-resources-card li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #00a0e3;
}

.resources-button {
  background-color: #00a0e3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 700;
  transition: background-color 0.3s;
}

.resources-button:hover {
  background-color: #0089c1;
}

/* Final CTA */
.final-cta {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  margin: 40px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-top: 4px solid #ff7f00;
}

.final-cta h2 {
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 20px;
}

/* Footer */
.footer {
  margin-top: 40px;
  padding: 30px 0;
  background-color: #333;
  color: white;
  border-radius: 8px 8px 0 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
}

.footer-info {
  text-align: center;
}

.footer-info h3 {
  color: white;
  margin-bottom: 10px;
}

.footer-info p {
  color: #ccc;
  margin-bottom: 5px;
}

.footer-contact {
  text-align: center;
}

.footer-phone {
  color: white;
  font-size: 1.3rem;
  font-weight: 700;
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff7f00;
  border-radius: 4px;
  margin-top: 10px;
}

.footer-bottom {
  text-align: center;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #555;
}

.footer-bottom p {
  color: #999;
  font-size: 0.9rem;
}

/* Location Banner */
.location-banner {
  background-color: #f0f0f0;
  padding: 15px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.location-info {
  text-align: center;
}

.location-info h3 {
  margin: 0;
  color: #333;
  font-size: 1.1rem;
}

.expert-distance {
  font-size: 1.3rem;
  margin: 10px 0;
  color: #333;
}

.service-rating {
  text-align: center;
  margin: 15px 0;
}

.service-rating p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #555;
}

.stars {
  color: #ff7f00;
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.call-button {
  background-color: #ff7f00;
  color: white;
  padding: 12px 20px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: background-color 0.3s, transform 0.3s;
}

.call-button:hover {
  background-color: #e67300;
  transform: translateY(-2px);
}

.out-of-service {
  text-align: center;
  width: 100%;
  padding: 15px;
}

.out-of-service p {
  margin-bottom: 10px;
}

.location-error, .loading {
  text-align: center;
  width: 100%;
  padding: 15px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.modal {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  animation: slideUp 0.3s;
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.close-modal {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  color: #999;
  cursor: pointer;
  transition: color 0.3s;
}

.close-modal:hover {
  color: #333;
}

.modal h2 {
  margin-bottom: 20px;
  color: #00a0e3;
  text-align: center;
  font-size: 1.8rem;
}

.modal form {
  display: flex;
  flex-direction: column;
}

.modal input, 
.modal select {
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.modal input:focus,
.modal select:focus {
  outline: none;
  border-color: #00a0e3;
  box-shadow: 0 0 0 2px rgba(0, 160, 227, 0.1);
}

.date-picker-container {
  margin-bottom: 15px;
}

.date-picker-container label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #555;
}

.custom-datepicker-input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.submit-button {
  background-color: #ff7f00;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 4px;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #e67300;
  transform: translateY(-2px);
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.form-footer {
  margin-top: 20px;
  text-align: center;
  font-size: 0.9rem;
  color: #666;
}

.modal-phone {
  color: #ff7f00;
  font-weight: 700;
  font-size: 1.1rem;
}

.error-message {
  color: #ff0000;
  margin-bottom: 15px;
  text-align: center;
}

.success-message {
  text-align: center;
  padding: 20px;
}

.success-message h2 {
  color: #00a0e3;
  margin-bottom: 15px;
}

.success-message p {
  margin-bottom: 10px;
  font-size: 1.1rem;
}

/* React Datepicker Customizations */
.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 8px 10px;
}

/* Responsive Styles */
@media screen and (min-width: 768px) {
  .flood-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .logo {
    margin-bottom: 0;
    flex: 1;
  }

  .logo img {
    margin: 0;
  }

  .emergency-banner {
    width: auto;
    flex: 1;
    margin: 0 20px;
  }

  .contact-info {
    align-items: flex-end;
    text-align: right;
    flex: 1;
  }

  .content-flex {
    flex-direction: row;
    align-items: center;
  }

  .text-content, .image-content {
    width: 48%;
  }

  .resources-section {
    flex-direction: row;
    align-items: flex-start;
  }

  .mascot {
    width: 150px;
  }

  .resto-resources-card {
    flex-grow: 1;
  }

  .location-banner {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
  }

  .location-info, .service-rating {
    text-align: left;
  }

  .call-button {
    width: auto;
  }

  .footer-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-info, .footer-contact {
    text-align: left;
  }

  .hero-overlay h1 {
    font-size: 3rem;
  }

  .hero-overlay h2 {
    font-size: 1.5rem;
  }
  .call-now {
color:white;
  }
}